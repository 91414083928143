import * as React from "react";
const catalogo =[
  {
    title:"Drives AC/DC",
    description:""
  },
  {
    title:"Tarjetas de control",
    description:""
  },
  {
    title:"UPS'S y fuentes de voltaje",
    description:""
  },
  {
    title:"Servodrives",
    description:""
  },
  {
    title:"Computadoras industriales",
    description:""
  },
  {
    title:"Equipos de ultrasonido",
    description:""
  }, 
  {
    title:"Servomotores",
    description:""
  },
  {
    title:"Dispositivos de retroalimentación",
    description:""
  },
  {
    title:"Corte y soldadura",
    description:""
  },
  {
    title:"Unidades de voltaje laser",
    description:""
  },
];
const items = catalogo.map((item,index)=>
  <div key={index}
    data-sal="slide-right"
    data-sal-delay={(index+1)%2 === 0 ? 250 : 300}
    data-sal-easing="ease"
  >
    <div>
    <h4 className="text-center grid-service-item-title">
      {item.title}
    </h4>
    </div>
  </div>
);
const CatalogoEquipos = ()=>{
  return(
    <div className="">
      <p className="mt-2 ps-4 ps-lg-0 pb-5 mb-5">
        Algo de nuestro catalogo de servicios...
      </p>
      <div className="grid-services">
        {items}
      </div>
    </div>

  );
}

export default CatalogoEquipos;
